import React from "react";
import Layout from "../components/Layout";

// stuff I would want to put on this page: relevant coursework, past work experience

export default function AboutPage() {
	return (
		<Layout>
			<main className="float-in">
				<h1>About</h1>
			</main>
		</Layout>
	)
}